import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { useMediaQuery } from 'react-responsive';
import styles from './DocumentsPage.module.scss';
import { Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/context';
import { SpecificationsPage } from '../SpecificationPage/SpecificationsPage';
import AddContract from './Components/AddContract/AddContract';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { Registry } from './Components/Registry/Registry';
import BuyerUpdAndRegistryPage from '../UpdAndRegistryPage/BuyerUpdAndRegistryPage';
import SupplierUpdAndRegistryPage from '../SupplierUpdAndRegistryPage/SupplierUpdAndRegistryPage';
import { DataResponseDocuments } from './type';

const DocumentsPage = () => {
    const [tab, setTab] = useState<string>(localStorage.getItem('tabOfDocumentsPage') || 'contractsAndSpecifications');

    const [openAddContract, setOpenAddContract] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataResponse, setDataResponse] = useState<DataResponseDocuments[]>();
    const [isFirstRender,setIsFirstRender] = useState<number>(0);
    const [activityOfSpecifications, setActivityOfSpecifications] = useState<{ value: string; label: string } | undefined | null>({
        value: 'open',
        label: 'Открытые договоры',
    });
    const { user } = useContext(Context);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();
    const getData = async () => {
        setLoading(true);
        try {
            const response = await fetchGet(`/information/contracts`, {
                status:activityOfSpecifications.value,
            });
            const responseTableData = response?.response?.map(item => {
                return {
                    ...item,
                    status:item?.status?.title,
                };
            });

            setDataResponse(responseTableData);

            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };
    useEffect(()=>{
        /*setIsFirstRender-избавляемся от лишних запросов при маунтинге страницы*/
        setIsFirstRender(prevState => (prevState+1));
        getData();
    },[]);

    useEffect(() => {
        if(isFirstRender>0){
            getData();
        }
    }, [activityOfSpecifications]);
    const onChange = (key: string) => {
        setTab(key);
        localStorage.setItem('tabOfDocumentsPage', key);
    };
    const registryAndUpdByRole={
    'carrier' : <Registry tab={tab} />,
    'buyer' : <BuyerUpdAndRegistryPage tab={tab} />,
    'supplier' : <SupplierUpdAndRegistryPage tab={tab} />,
    };

    const tabs: {
        key: string;
        label: string;
        children: JSX.Element;
    }[] = [
        {
            key: 'contractsAndSpecifications',
            label: 'Договоры и спецификации',
            children: (
                <>
                    <SpecificationsPage activityOfSpecifications={activityOfSpecifications} setActivityOfSpecifications={setActivityOfSpecifications}
                                        getData={getData} dataResponse={dataResponse} loading={loading} tab={tab} isFirstRender={isFirstRender}/>
                </>
            ),
        },
        {
            key: 'registryAndUpd',
            label: `${user.companyType === 'carrier' ? 'Реестр' : 'УПД'}`,
            children: (
                <>
                    {registryAndUpdByRole[user.companyType]}
                </>
            ),
        },
    ];
    return (
        <>
          <PageWrapper>
              <div className={styles.titleAndSubTitleContainer}>
                  <div className={styles.titleAndAddButton}>
                      <TitleMain title={'Документы'} />
                      {isMobile &&
                          <SubTitleMain
                              subTitle={`Общий список документов`}
                          />
                      }
                      {user.companyType === 'carrier' &&
                          <ButtonFilled
                              margin={isMobile?'12px 0 0 0' : '0'}
                              sizeText={'small'}
                              svg={tab === 'contractsAndSpecifications' && <NewPlusSvg />}
                              width={isMobile ? '100%' : '185px'}
                              text={tab === 'registryAndUpd'
                                  ? 'Сформировать реестр'
                                  : 'Добавить договор'}
                              onClick={tab === 'registryAndUpd'
                                  ? ()=>navigate('registry/registries')
                                  : ()=>setOpenAddContract(true)}
                          />
                      }
                      {(user.companyType !== 'carrier'
                        && user.companyType !== 'supplier'
                        && user.companyType !== 'buyer'
                        && tab === 'contractsAndSpecifications') &&
                          <ButtonFilled
                              margin={isMobile?'12px 0 0 0' : '0'}
                              sizeText={'small'}
                              svg={<NewPlusSvg />}
                              width={isMobile ? '100%' : '185px'}
                              text={'Добавить договор'}
                              onClick={()=>setOpenAddContract(true)}
                          />
                      }
                      <AddContract getData={getData} isFirstRender={isFirstRender}
                                   openAddContract={openAddContract} setOpenAddContract={setOpenAddContract}/>
                  </div>
                  {!isMobile &&
                      <SubTitleMain
                          subTitle={`Общий список документов`}
                      />
                  }
              </div>
              <Tabs defaultActiveKey={tab} items={tabs} onChange={onChange} />
          </PageWrapper>
        </>
    );
};

export default DocumentsPage;
