import React, { useEffect, useState } from 'react';
import styles from './CatalogFilter.module.scss';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import FilterBox from 'src/components/newUI/FilterBox/FilterBox';
import { SelectCustom } from 'src/components/UI/select/SelectCustom';
import { PriceFilterInput } from '../components/PriceFilterInput/PriceFilterInput';
import { useMediaQuery } from 'react-responsive';
import { formatNumber } from '../utils';
import { TCatalogFilter, TFilter, TFilterItem, TFilterValues } from '../catalogTypes';
import { defaultFilter, defaultFilterValues, sortValues } from '../catalogConstants';
import { SortBox } from 'src/components/SortBox/SortBox';
import { ShowedFilters } from '../components/ShowedFilters/ShowedFilters';

export const CatalogFilter = ({ mockFilterValues }: TCatalogFilter) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [isDisabledFilterButton, setIsDisabledFilterButton] = useState<boolean>(true);
  const [filter, setFilter] = useState<TFilter>(defaultFilter);
  const [filterValues, setFilterValues] = useState<TFilterValues>(defaultFilterValues);
  const [combinedFilter, setCombinedFilter] = useState<TFilterItem[]>([]);
  const [sort, setSort] = useState<TFilterItem>();
  const [openSort, setOpenSort] = useState<boolean>(false);

  useEffect(() => {
    setFilterValues(mockFilterValues);
  }, []);

  useEffect(() => {
    const isEmptyFilters =
      !filter.objectPlace &&
      !filter.materialType.length &&
      !filter.materialFraction.length &&
      !filter.priceRange.to &&
      !filter.priceRange.from;

    setIsDisabledFilterButton(isEmptyFilters);
    setCombinedFilter([
      ...(!filter.objectPlace ? [] : [filter.objectPlace]),
      ...filter.materialType,
      ...filter.materialFraction,
      ...(filter.priceRange.from || filter.priceRange.to
        ? [{ value: 'price', label: `От ${filter.priceRange.from || '0'} до ${filter.priceRange.to || '999 999'}` }]
        : []),
    ]);
  }, [filter]);

  useEffect(() => {
    if (!filter.materialType.length)
      setFilter(prevState => ({
        ...prevState,
        materialFraction: [],
      }));
  }, [filter.materialType]);

  const clearFilters = () => {
    setFilter(prevState => ({
      ...prevState,
      objectPlace: null,
      materialType: [],
      materialFraction: [],
      priceRange: { from: '', to: '' },
    }));
  };

  const deleteFilter = (filterLabel: string, filterValue: string) => {
    setFilter(prevFilter => {
      const updatedFilter: TFilter = {
        ...prevFilter,
        objectPlace:
          !prevFilter.objectPlace || prevFilter.objectPlace.label === filterLabel ? null : prevFilter.objectPlace,
        materialType: prevFilter.materialType.filter(item => item.label !== filterLabel),
        materialFraction: prevFilter.materialFraction.filter(item => item.label !== filterLabel),
        priceRange: {
          from: filterValue === 'price' ? '' : prevFilter.priceRange.from,
          to: filterValue === 'price' ? '' : prevFilter.priceRange.to,
        },
      };
      return updatedFilter;
    });

    setCombinedFilter(prevCombinedValues => prevCombinedValues.filter(item => item.label !== filterLabel));
  };
  return (
    <div className={styles.container}>
      <InputSearch
        placeholder={'Поиск'}
        onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
        value={filter.search}
      />
      <div className={styles.searchContainer}>
        <SortBox
          openSort={openSort}
          setOpenSort={setOpenSort}
          widthButton={isMobile ? '100%' : '242px'}
          sortOptions={sortValues}
          sort={sort}
          setSort={setSort}
        />
        <FilterBox
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          clearFilters={clearFilters}
          widthButton={isMobile ? '100%' : '290px'}
          disabled={isDisabledFilterButton}
          label="Фильтры"
          disabledLabel="Фильтры"
        >
          <h1 className={styles.filterHeader}>Фильтры</h1>
          <div className={styles.filtersContainer}>
            <div className={styles.filterWithHeader}>
              <h2 className={styles.filterSubHeader}>Куда доставить</h2>
              <SelectCustom
                options={filterValues.objectPlace}
                maxTagCount={1}
                maxCount={1}
                placeholder={'Выберите объект из списка'}
                mode={null}
                value={filter.objectPlace}
                setValue={value => setFilter(prevState => ({ ...prevState, objectPlace: value }))}
                disabled={false}
                status={''}
                errorText={''}
              />
            </div>
            <div className={styles.filterWithHeader}>
              <h2 className={styles.filterSubHeader}>Тип материала</h2>
              <SelectCustom
                options={filterValues.materialType}
                maxTagCount={3}
                maxCount={filterValues.materialType.length}
                placeholder={'Выберите из списка'}
                mode={'multiple'}
                value={filter.materialType}
                setValue={value => setFilter(prevState => ({ ...prevState, materialType: value }))}
                disabled={false}
                status={''}
                errorText={''}
              />
            </div>
            <div className={styles.filterWithHeader}>
              <h2 className={styles.filterSubHeader}>Фракция материала</h2>
              <SelectCustom
                options={filterValues.materialFraction}
                maxTagCount={3}
                maxCount={filterValues.materialFraction.length}
                placeholder={'Выберите из списка'}
                mode={'multiple'}
                value={filter.materialFraction}
                setValue={value => setFilter(prevState => ({ ...prevState, materialFraction: value }))}
                disabled={!filter.materialType.length}
                status={''}
                errorText={''}
              />
            </div>
            <div className={styles.filterWithHeader}>
              <h2 className={styles.filterSubHeader}>Диапазон цены, ₽</h2>
              <div className={styles.priceFilter}>
                <p>От</p>
                <PriceFilterInput
                  value={filter.priceRange.from}
                  placeholder={'0'}
                  setValue={value =>
                    setFilter(prevState => ({
                      ...prevState,
                      priceRange: {
                        ...prevState.priceRange,
                        from: value,
                      },
                    }))
                  }
                />
                <p>до</p>
                <PriceFilterInput
                  value={filter.priceRange.to}
                  placeholder={'999 999'}
                  setValue={value =>
                    setFilter(prevState => ({
                      ...prevState,
                      priceRange: {
                        ...prevState.priceRange,
                        to: value,
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </FilterBox>
        {!isDisabledFilterButton &&
          (!isMobile ? (
            <>
              <ShowedFilters combinedFilter={combinedFilter} deleteFilter={deleteFilter} clearFilters={clearFilters} />
            </>
          ) : (
            <div className={styles.showedFilters}>
              <ShowedFilters combinedFilter={combinedFilter} deleteFilter={deleteFilter} clearFilters={clearFilters} />
            </div>
          ))}
      </div>
    </div>
  );
};
