import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Header } from '../components/Header/Header';
import { Outlet } from 'react-router-dom';
import { ButtonClose } from '../components/UI/buttons/buttonClose';
import { Container } from '../components/Container';
import { Context } from '../context/context';
import NewAsideNavCarrierMobile from '../components/asideNav/NewAsideNavCarrierMobile';
import NewAsideNavCarrier from '../components/asideNav/NewAsideNavCarrier';
import FooterLk from '../components/newUI/FooterLk/FooterLk';
import MobileTour from "../components/asideNav/components/MobileTour/MobileTour";

export const LayoutCarrier = () => {
  const { openDescription, setOpenDescription, helpTitle, helpDescription } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { startTour } = useContext(Context);
  return (
    <>
      {!isMobile && <Header />}
      {isMobile ? (<div>
            {startTour?
                <div>
                  <MobileTour/>
                </div>:
                <div id="Page" className="Page" style={{minWidth:'100%'}}>
                  <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
                    <Outlet />
                  </div>
                  <NewAsideNavCarrierMobile />
                  <div
                      onClick={() => setOpenDescription(false)}
                      className={openDescription ? 'PopUpHelp active' : 'PopUpHelp'}
                  ></div>
                  <div className={openDescription ? 'PopUpHelp__content active' : 'PopUpHelp__content'}>
                    <ButtonClose
                        onClick={() => setOpenDescription(false)}
                        hidden={openDescription ? 'active' : 'hidden'}
                        style={{ zIndex: '1200', position: 'absolute', top: '6px', right: '20px' }}
                    />
                    <div className="PopUpHelp__title">{helpTitle}</div>
                    <div className="PopUpHelp__description">{helpDescription}</div>
                  </div>

                  <FooterLk />
                </div>
            }

      </div>

      ) : (
        <div className="Page">
          <Container>
            <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
              <div className="Aside">
                <NewAsideNavCarrier />
              </div>
              {/*<a href='https://project9474365.tilda.ws/logistic'>
                <div className="btnHelpSupport">
                  <SupportSvg/>
                </div>
              </a>*/}
              <div className="content">
                <Outlet />
              </div>
            </div>
          </Container>
          <FooterLk />
        </div>
      )}
    </>
  );
};
