import { TCatalogMaterialCategory, TFilter, TFilterItem, TFilterValues } from './catalogTypes';
import crushedStone from './images/crushed-stone.png';
import secondaryCrushedStone from './images/secondary-crushed-stone.png';
import sand from './images/sand.png';
import PGS from './images/PGS.png';
import SHPS from './images/SHPS.png';
import mobileCrushedStone from './images/mobile-crushed-stone.png';
import mobileSecondaryCrushedStone from './images/mobile-secondary-crushed-stone.png';
import mobileSand from './images/mobile-sand.png';
import mobilePGS from './images/mobile-PGS.png';
import mobileSHPS from './images/mobile-SHPS.png';
export const mockFilterValues: TFilterValues = {
  objectPlace: [
    { value: 'address1', label: 'Адрес1' },
    { value: 'address2', label: 'Адрес2' },
    { value: 'address3', label: 'Адрес3' },
  ],
  materialType: [
    { value: 'crushedStone', label: 'Щебень' },
    { value: 'sand', label: 'Песок' },
    { value: 'SGM', label: 'ПГС' },
  ],
  materialFraction: [
    { value: '0-20', label: '0-20' },
    { value: '20-40', label: '20-40' },
    { value: '40-70', label: '40-70' },
  ],
  priceRange: { from: '5', to: '1700' },
};

export const catalogMaterialCategories: TCatalogMaterialCategory[] = [
  {
    title: 'Щебень',
    subtitle: 'Материал \nдля строительства',
    image: crushedStone,
    mobileImage: mobileCrushedStone,
    textColor: 'var(--color-orange400)',
    subtextColor: 'var(--color-gray900)',
  },
  {
    title: 'Вторичный щебень',
    image: secondaryCrushedStone,
    mobileImage: mobileSecondaryCrushedStone,
    textColor: 'white',
  },
  {
    title: 'Песок',
    subtitle: 'Материал \nдля строительства',
    image: sand,
    mobileImage: mobileSand,
    textColor: 'white',
  },
  {
    title: 'ЩПС',
    subtitle: 'Щебеночно-песчаная смесь',
    image: SHPS,
    mobileImage: mobileSHPS,
    textColor: 'var(--color-gray900)',
  },
  { title: 'ПГС', subtitle: 'Песчано-гравийная смесь', image: PGS, mobileImage: mobilePGS, textColor: 'white' },
];

export const defaultFilterValues: TFilterValues = {
  objectPlace: [],
  materialType: [],
  materialFraction: [],
  priceRange: { from: '', to: '' },
};

export const defaultFilter: TFilter = {
  search: '',
  objectPlace: null,
  materialType: [],
  materialFraction: [],
  priceRange: { from: '', to: '' },
};

export const sortValues: TFilterItem[] = [
  { value: 'expensive', label: 'Дороже' },
  { value: 'cheap', label: 'Дешевле' },
  { value: 'moreStock', label: 'С большим остатком' },
  { value: 'lessStock', label: 'С меньшим остатком' },
];
