import React, { useState } from 'react';
import { WarningEmptySvg } from '../svg-icon/WarningEmptySvg';
import CustomTooltip from '../../newUI/CustomTooltip/CustomTooltip';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { HelpNewSvg } from '../svg-icon/HelpNewSvg';
import { CloseCircleNewSvg } from '../svg-icon/CloseCircleNewSvg';

type Props = {
  isLabel: boolean; // Наличие заголовка
  margin: string;
  width?: string;
  error: boolean;
  showError?: boolean;
  type: string;
  value: any;
  textError: string;
  placeholder: string;
  setValue: React.Dispatch<any>;
  disabled: boolean;
  clearButton?: boolean;
  helpText: string | ReactJSXElement;
  widthHelp?: any;
  widthSvg?: string;
  svgColor?: string;
  modificator?: string;
  additionalSvg?: ReactJSXElement;
  changeStyleLabel?: boolean;
  [key: string]: any;
};

export const InputText = ({
  isLabel,
  margin,
  error,
  showError,
  clearButton,
  type,
  value,
  textError,
  placeholder,
  setValue,
  disabled,
  helpText,
  widthHelp,
  width,
  widthSvg = '16px',
  svgColor = '#1C274C',
  modificator = '',
  additionalSvg = <></>,
  changeStyleLabel=false,
  ...props
}: Props) => {
  const [errorOnFocus, setErrorOnFocus] = useState(true);
  return (
    <div style={{position: 'relative'}}>
      <div
        className={`InputText__wrapper ${(errorOnFocus && error && !value) || (errorOnFocus && showError) ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
        style={{ marginTop: margin, width: width }}
      >
        <div style={{ display: (helpText || clearButton) && 'flex' }} className={`InputText form-item ${modificator}`}>
          <input
            {...props}
            className={`InputText__input form-input`}
            type={type}
            disabled={disabled}
            value={value || ''}
            onChange={({ target: { value } }) => setValue(String(value))}
            onBlur={() => setErrorOnFocus(error)}
            onFocus={() => setErrorOnFocus(false)}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '4px 16px 0 0' }}>{additionalSvg}</div>
          <div style={{ display: 'flex' }}>
            {clearButton && (
              <div
                onClick={() => setValue((value = ''))}
                style={{ display: 'flex', alignItems: 'center', marginRight: '16px', cursor: 'pointer' }}
              >
                <CloseCircleNewSvg width={widthSvg} />
              </div>
            )}
            {helpText && (
              <CustomTooltip
                  positionTooltipBottom={'28px'}
                  positionTooltipRight={'-11px'}
                  SideTooltip={'left'}
                  MobileSideTooltip={'left'}
                  style={{ marginRight: '16px' }}
                  svg={<HelpNewSvg color={svgColor} />}
                  text={helpText}
                  widthSvg={widthSvg}
                  widthTooltip={widthHelp ? widthHelp : '311px'}
              />
            )}
          </div>
          {isLabel && (
            <label
            className={`InputText__label form-label ${value ? 'valid' : ''}`}
            style={
              changeStyleLabel
                ? { color: 'var(--color-gray400)!important', WebkitTextFillColor: 'var(--color-gray400)!important' }
                : {}
            }
          >
              {placeholder}
            </label>
          )}
        </div>
      </div>
      {((errorOnFocus && error && !value) || (errorOnFocus && showError)) && <div className={'InputText__text'}>{textError}</div>}
    </div>
  );
};
