import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styles from './Catalog.module.scss';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { PageWrapper } from 'src/components/PageWrapper';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { TFilterValues } from './catalogTypes';
import { CatalogFilter } from './CatalogFilter/CatalogFilter';
import { mockFilterValues } from './catalogConstants';
import { CatalogMaterialCategories } from './CatalogMaterialCategories/CatalogMaterialCategories';

export const Catalog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.titleAndSubtitleAndButtonContainer}>
          <div className={styles.titleAndButtonContainer}>
            <TitleMain title={'Каталог'} />
            {!isMobile && <ButtonNotFilled width={'186px'} sizeText={'small'} text={'Перейти в избранное'} />}
          </div>
          <div className={styles.subTitleContainer}>
            <SubTitleMain
              subTitle={
                'Просматривайте материалы, оформляйте заказ и отслеживайте его статус в режиме реального времени'
              }
            />
          </div>
        </div>
        <CatalogFilter mockFilterValues={mockFilterValues} />
        <CatalogMaterialCategories />
      </div>
      {loading ? <LoadingSpin /> : <></>}
    </PageWrapper>
  );
};
