import React, { useContext, useEffect, useState } from 'react';
import styles from './NewAuthRole.module.scss';
import { useMediaQuery } from 'react-responsive';
import AuthLineSvg from '../../components/UI/svg-icon/AuthLineSvg';
import DumpTruckSvg from '../../components/UI/svg-icon/DumpTruckSvg';
import HandShakeSvg from '../../components/UI/svg-icon/HandShakeSvg';
import WorkerSvg from '../../components/UI/svg-icon/WorkerSvg';
import { Context } from '../../context/context';
import { NewUserTypes } from '../../common/user/new-user-types.type';
import { NewRoleTab } from './NewRoleTad/NewRoleTab';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import { Button } from '../../components/UI/buttons/Button';
import FooterAuth from './footerAuth/FooterAuth';
import LogoIconSvg from '../../components/UI/svg-icon/LogoIconSvg';
import LogoTextSvg from '../../components/UI/svg-icon/LogoTextSvg';
import DumpTruckMobileSvg from '../../components/UI/svg-icon/DumpTruckMobileSvg';
import HandShakeMobileSvg from '../../components/UI/svg-icon/HandShakeMobileSvg';
import WorkerMobileSvg from '../../components/UI/svg-icon/WorkerMobileSvg';
import AuthLineMobileSvg from '../../components/UI/svg-icon/AuthLineMobileSvg';
import NewDriverRoleSvg from '../../components/UI/svg-icon/NewDriverRoleSvg';
import { useNavigate } from 'react-router-dom';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import LogoMobileSvg from '../../components/UI/svg-icon/LogoMobileSvg';

export type NewStatusTabsRole = {
  // eslint-disable-next-line no-unused-vars
  [key in NewUserTypes]: {
    name: string;
    description: string;
    svg: JSX.Element;
    active: boolean;
    disabled: boolean;
    development: boolean;
  };
};

const NewAuthRole = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const NewTabsRole: NewStatusTabsRole = {
    carrier: {
      name: 'Перевозчик',
      description: 'Распределение рейсов, добавление водителей, машин, топливных карт',
      svg: isMobile ? <DumpTruckMobileSvg /> : <DumpTruckSvg />,
      active: false,
      disabled: false,
      development: true,
    },
    driver: {
      name: 'Водитель',
      description: 'Исполнение и контроль рейсов, загрузка и просмотр транспортных накладных ',
      svg: isMobile ? <NewDriverRoleSvg /> : <NewDriverRoleSvg />,
      active: false,
      disabled: false,
      development: true,
    },
    buyer: {
      name: 'Покупатель',
      description: 'Покупка, оформление и отслеживание заказов в реальном времени',
      svg: isMobile ? <HandShakeMobileSvg /> : <HandShakeSvg />,
      active: false,
      disabled: false,
      development: true,
    },
    supplier: {
      name: 'Поставщик',
      description: 'Отслеживание погрузочных накладных, взаиморасчеты, реестры материала',
      svg: isMobile ? <WorkerMobileSvg /> : <WorkerSvg />,
      active: false,
      disabled: false,
      development: true,
    },
  };
  const [roleTabs, setRoleTabs] = useState<NewStatusTabsRole>(NewTabsRole);
  const [disabled, setDisabled] = useState(true);
  const { user, setUser } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
  }, []);

  const pickRole = (tabName: string) => {
    for (const [key, tab] of Object.entries(roleTabs)) {
      tab.active = key === tabName;
    }

    setRoleTabs(prevState => ({ ...prevState, ...roleTabs }));
    setDisabled(false);
  };

  const setRole = () => {
    for (const [type, tab] of Object.entries(roleTabs)) {
      if (tab.active) {
        setUser({ ...user, type, isFirstLogin: true });
        localStorageWrite({ ...user, type, isFirstLogin: true });
        localStorage.setItem('RoleType', type);
        navigate(`/register_${type}`);
      }
    }
  };

  return (
    <div className={styles.mainContainer}>
      {isMobile ? (
        <div className={styles.container}>
          <div className={styles.headerLogo}>
            <div className={styles.headerLogoContent}>
              <LogoMobileSvg />
              <p className={styles.textLogo}>
                Платформа нерудных
                <br />
                материалов
              </p>
            </div>
          </div>
          <div className={styles.roleContainer}>
            <div className={styles.svgContainer}>
              <AuthLineMobileSvg />
            </div>
            <div className={styles.headerTextContainer}>
              <h1 className={styles.titleTextContainer}>Выберите вашу роль в нашей платформе</h1>
              <div className={styles.descriptionTextBlock}>
                <p>Выберите роль из представленных ниже. Данная роль будет привязана к вашему номеру.</p>
              </div>
            </div>
            <div className={styles.inputContainer}>
              {Object.entries(roleTabs).map(([tabName, tab]) => (
                <NewRoleTab
                  key={tabName}
                  onClick={() => pickRole(tabName)}
                  active={tab.active}
                  name={tab.name}
                  description={tab.description}
                  svg={tab.svg}
                  disabled={tab.disabled}
                  development={tab.development}
                />
              ))}
              <div style={{ marginTop: '24px' }}>
                <ButtonFilled text={'Далее'} width={'100%'} onClick={() => setRole()} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        /*компьютерная версия*/
        <>
          <div className={styles.container}>
            <div className={styles.roleContainer}>
              <div className={styles.svgContainer}>
                <AuthLineSvg />
              </div>
              <div className={styles.headerTextContainer}>
                <h1 className={styles.titleTextContainer}>Выберите вашу роль в нашей платформе</h1>
                <div className={styles.descriptionTextBlock}>
                  <p className={styles.descriptionTextContainer}>
                    Выберите роль из представленных ниже. Данная роль будет привязана к вашему номеру.
                  </p>
                </div>
              </div>
              <div>
                <div className={styles.inputContainer}>
                  {Object.entries(roleTabs).map(([tabName, tab]) => (
                    <NewRoleTab
                      key={tabName}
                      onClick={() => pickRole(tabName)}
                      active={tab.active}
                      name={tab.name}
                      description={tab.description}
                      svg={tab.svg}
                      disabled={tab.disabled}
                      development={tab.development}
                    />
                  ))}
                </div>
                <div className={styles.inputContainerBtn}>
                  <div style={{ marginTop: '24px' }}>
                    <ButtonFilled text={'Далее'} width={'100%'} onClick={() => setRole()} disabled={disabled} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAuth />
        </>
      )}
    </div>
  );
};

export default NewAuthRole;
