import React, { useContext, useRef, useState } from 'react';
import { WalletSvg } from '../UI/svg-icon/WalletSvg';
import { WalletSvgActive } from '../UI/svg-icon/WalletSvgActive';
import { DocumentSvgActive } from '../UI/svg-icon/DocumentSvgActive';
import { ProfileErrorSvg } from '../UI/svg-icon/ProfileErrorSvg';
import { ProfileSvg } from '../UI/svg-icon/ProfileSvg';
import { ProfileSvgActive } from '../UI/svg-icon/ProfileSvgActive';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import NewNavItem from '../newUI/NewNavItem/NewNavItem';
import { FlightSvg } from '../UI/svg-icon/FlightSvg';
import { FlightSvgActive } from '../UI/svg-icon/FlightSvgActive';
import { DriverLicenseSvg } from '../UI/svg-icon/DriverLicenseSvg';
import { DriverLicenseSvgActive } from '../UI/svg-icon/DriverLicenseSvgActive';
import { TruckSvg } from '../UI/svg-icon/TruckSvg';
import { TruckSvgActive } from '../UI/svg-icon/TruckSvgActive';
import { DocumentSvg } from '../UI/svg-icon/DocumentSvg';
import MonitorDashboardSvg from '../UI/svg-icon/MonitorDashboardSvg';
import MonitorDashboardActiveSvg from '../UI/svg-icon/MonitorDashboardActiveSvg';
import EmployeesSvg from '../UI/svg-icon/EmployeesSvg';
import EmployeesActiveSvg from '../UI/svg-icon/EmployeesActiveSvg';
import { Space, Tour, TourProps } from 'antd';
import stylesTour from './Tour.module.scss';
import DispatchTourUImg from '../../assets/img/TourImages/dispatchTourUImg.jpg';
import FlightTourImg from '../../assets/img/TourImages/FlightTourImg.png';
import DriverTourImg from '../../assets/img/TourImages/DriverTourImg.png';
import CarTourImg from '../../assets/img/TourImages/CarTourImg.png';
import EmployeesTourImg from '../../assets/img/TourImages/EmployeesTourImg.png';
import DocumentTourImg from '../../assets/img/TourImages/DocumentTourImg.png';
import PaymentProcessTourImg from '../../assets/img/TourImages/PaymentProcessTourImg.png';
import ProfileTourImg from '../../assets/img/TourImages/ProfileTourImg.png';
import { Context } from '../../context/context';
const fullAccess = ['admin', 'manager', 'carrier', 'accountant'];

const NewAsideNavCarrier = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const { setStartTour, startTour, user } = useContext(Context);

  const steps: any = [
    {
      title: <p className={stylesTour.title}>Диспетчеризация</p>,
      typeRole: ['admin', 'carrier', 'manager'],
      description:
        'Распределяйте груз между рейсами и транспортными средствами, назначайте водителей.' +
        ' Отказывайтесь от остатков в случае необходимости и редактируйте планирование.' +
        ' Просматривайте общий список выполненных объемов в подразделе «Выполненные заявки».',
      placement: 'right',
      type: 'default',
      cover: <img className={styles.tourImg} alt="tour.png" src={DispatchTourUImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnFirstNext },
      target: () => ref1.current,
    },
    {
      title: <p className={stylesTour.title}>Рейсы</p>,
      placement: 'right',
      typeRole: ['admin', 'carrier', 'manager'],
      cover: <img alt="tour.png" src={FlightTourImg} />,
      description:
        'Отслеживайте статус выполнения рейсов водителями, ' +
        'просматривайте полную информацию по рейсам, ' +
        'переназначайте водителей и транспортное средство, отказывайтесь от рейсов до выезда водителей.',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      target: () => ref2.current,
    },
    {
      title:<p className={stylesTour.title}>Водители</p>,
      placement: 'right',
      typeRole: ['admin', 'carrier'],
      type: 'default',
      cover: <img alt="tour.png" src={DriverTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и удаляйте водителей, чтобы назначать их на рейсы. Укажите их личные и паспортные данные, ' +
        'а также прикрепите водительское удостоверение и паспорт для регистрации.\n' +
        'После добавления водитель получит уведомление о регистрации и сможет войти в свой личный кабинет. ' +
        'В случае удаления водителя его номер телефона будет удален из системы, а доступ к личному кабинету - потерян.\n',
      target: () => ref3.current,
    },
    {
      title: <p className={stylesTour.title}>Транспортные средства</p>,
      placement: 'right',
      typeRole: ['admin', 'carrier'],
      type: 'default',
      cover: <img alt="tour.png" src={CarTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и удаляйте транспортные средства, распределяйте их между рейсами. ' +
        'Укажите номер и марку автомобиля, добавьте файл СТС и договор лизинга при наличии.',
      target: () => ref4.current,
    },
    {
      title: <p className={stylesTour.title}>Сотрудники</p>,
      placement: 'right',
      typeRole: ['carrier'],
      type: 'default',
      cover: <img alt="tour.png" src={EmployeesTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и удаляйте сотрудников и назначайте им роли менеджера, бухгалтера и администратора.' +
        ' У каждого сотрудника доступен свой функционал личного кабинета, необходимый для работы.\n' +
        'После добавления сотрудника он получит уведомление о регистрации и сможет войти в личный кабинет.' +
        ' При удалении сотрудник потеряет доступ к кабинету, его номер также удалится из системы.',
      target: () => ref5.current,
    },
    {
      title: <p className={stylesTour.title}>Документы</p>,
      placement: 'right',
      typeRole: ['admin', 'carrier', 'accountant'],
      type: 'default',
      cover: <img alt="tour.png" src={DocumentTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Формируйте договоры поставки или перевозки и заверяйте их электронной подписью. ' +
        'Просматривайте уже сформированные договоры, а также спецификации к ним.\n' +
        'В подразделе «Реестры» вы можете формировать электронный список реестров и отправлять его на проверку.\n',
      target: () => ref6.current,
    },
    {
      title: <p className={stylesTour.title}>Взаиморасчеты</p>,
      placement: 'right',
      typeRole: ['admin', 'carrier', 'accountant'],
      type: 'default',
      cover: <img alt="tour.png" src={PaymentProcessTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Просматривайте количество оплаченных и неоплаченных рейсов, следите за суммой оплаты по оригиналам и сканам транспортных накладных.',
      target: () => ref7.current,
    },
    {
      title:<p className={stylesTour.title}>Профиль</p>,
      placement: 'right',
      type: 'default',
      typeRole: fullAccess,
      cover: <img width={'600px'} alt="tour.png" src={ProfileTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и просматривайте данные, редактируйте их при необходимости. \n' +
        'Завершите регистрацию: заполните личные и юридические данные, прикрепите учредительные документы. ' +
        'Затем ваш профиль отправится на проверку, после прохождения которой вы сможете работать в личном кабинете.\n',
      target: () => ref8.current,
    },
    {
      title: <p className={stylesTour.title}>Помощь</p>,
      placement: 'right',
      type: 'default',
      typeRole: fullAccess,
      nextButtonProps: { children: 'Завершить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description: 'Изучайте ответы на наиболее часто задаваемые вопросы.',
      target: () => ref9.current,
    },
  ].filter(item => item?.typeRole?.includes(user?.type));
  const navLink = [
    {
      name: 'Диспетчеризация',
      ref: ref1,
      link: '/dispatching',
      type: ['admin', 'carrier', 'manager'],
      notActiveProfile: false,
      disable: true,
      svg: <MonitorDashboardSvg />,
      svgActive: <MonitorDashboardActiveSvg />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Рейсы',
      ref: ref2,
      link: '/flights',
      type: ['admin', 'carrier', 'manager'],
      notActiveProfile: false,
      disable: false,
      svg: <FlightSvg />,
      svgActive: <FlightSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Водители',
      link: '/driverLicense',
      ref: ref3,
      type: ['admin', 'carrier'],
      notActiveProfile: false,
      disable: true,
      svg: <DriverLicenseSvg />,
      svgActive: <DriverLicenseSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Машины',
      link: '/cars',
      ref: ref4,
      type: ['admin', 'carrier'],
      notActiveProfile: false,
      disable: true,
      svg: <TruckSvg />,
      svgActive: <TruckSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Сотрудники',
      link: '/employees',
      ref: ref5,
      type: ['carrier'],
      notActiveProfile: false,
      disable: true,
      svg: <EmployeesSvg />,
      svgActive: <EmployeesActiveSvg />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Документы',
      link: '/document',
      ref: ref6,
      type: ['admin', 'carrier', 'accountant'],
      notActiveProfile: false,
      disable: true,
      svg: <DocumentSvg />,
      svgActive: <DocumentSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Взаиморасчеты',
      link: '/paymentProcess#Деньги',
      ref: ref7,
      type: ['admin', 'carrier', 'accountant'],
      notActiveProfile: false,
      disable: true,
      svg: <WalletSvg />,
      svgActive: <WalletSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Профиль',
      link: '/profile',
      ref: ref8,
      type: fullAccess,
      notActiveProfile: true,
      disable: false,
      svg: <ProfileSvg />,
      svgActive: <ProfileSvgActive />,
      svgProfileError: <ProfileErrorSvg />,
      errorProfile: true,
    },
    /*{
          name: 'ГСМ',
          link: '/gsm',
          notActiveProfile: false,
          disable: true,
          svg: <GsmSvg />,
          svgActive: <GsmSvgActive />,
          svgProfileError: null,
          errorProfile: false,
      },*/
    {
      name: 'Помощь',
      link: '/help',
      ref: ref9,
      notActiveProfile: false,
      type: fullAccess,
      disable: true,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
  ].filter(item => item?.type?.includes(user?.type));
  return (
    <div className={styles.AsideBox} style={{ zIndex: startTour ? '1000' : '9000' }}>
      {navLink.map(({ name, ref, type, link, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => {
        {
          return (
            <div ref={ref}>
                <NewNavItem
                  disabled={null}
                  typeAccess={type}
                  key={name}
                  name={name}
                  link={link}
                  notActiveProfile={notActiveProfile}
                  svg={svg}
                  svgActive={svgActive}
                  svgProfileError={svgProfileError}
                  errorProfile={errorProfile}
                />
            </div>
          );
        }
      })}
      <Tour
        closeIcon={false}
        open={startTour}
        gap={{ offset: [-8, -2], radius: 12 }}
        onClose={() => setStartTour(false)}
        steps={steps}
        indicatorsRender={(current, total) => <span></span>}
      />
    </div>
  );
};

export default NewAsideNavCarrier;
