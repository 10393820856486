// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Catalog_container__KyYkO {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.Catalog_titleAndButtonContainer__Yvoi6 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.Catalog_titleAndSubtitleAndButtonContainer__icHv6 {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n@media (max-width: 768px) {\n  .Catalog_container__KyYkO {\n    gap: 24px;\n  }\n  .Catalog_titleAndSubtitleAndButtonContainer__icHv6 {\n    gap: 4;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/Catalog/Catalog.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;EAEA;IACE,MAAA;EAAF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.titleAndButtonContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.titleAndSubtitleAndButtonContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n\n  .titleAndSubtitleAndButtonContainer {\n    gap: 4;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Catalog_container__KyYkO",
	"titleAndButtonContainer": "Catalog_titleAndButtonContainer__Yvoi6",
	"titleAndSubtitleAndButtonContainer": "Catalog_titleAndSubtitleAndButtonContainer__icHv6"
};
export default ___CSS_LOADER_EXPORT___;
