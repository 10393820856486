import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { DriverLicenseList } from '../../components/DriverLicenseList';
import { InputText } from '../../components/UI/inputs/InputText';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { useMediaQuery } from 'react-responsive';
import { ErrorText } from '../../components/ErrorText';
import { profileData } from '../../common/profile.type';
import { closePopUp, openPopUp } from '../../common/use-popup.functiopn';
import { toast, ToastContainer } from 'react-toastify';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import { Context } from '../../context/context';
import styles from './DriversLicensePage.module.scss';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import useDebounce from '../../CustomHooks/useDebounce';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { removeImage } from '../../common/removeImage';
import { Image } from '../../common/image.type';
import { SUBSERVER } from '../../common/sub-server.constant';
import { ProfilePageUploading } from '../../components/ProfilePageUploading';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import { declinationWord } from '../../common/declinationWord.helper';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { EmptyList } from '../../components/EmptyList';
import CustomDatePicker from '../../components/newUI/CustomDatePicker/CustomDatePicker';
import { validateEmail } from 'src/common/validation-email.function';
import { upperCaseFirstLetter } from 'src/common/uppercase-first-letter.helper';
import {clearObject} from "../../common/ClearObject.helper";

export const validationPassport = (value, setProfile, setValue, numberOfSymbols) => {
  const regex = /^[0-9]*[A-Z]*[0-9]*$/;
  if (value.length <= numberOfSymbols && regex.test(value)) setProfile(setValue);
};

export const validationPassportSeries = (value, setProfile, setValue, numberOfSymbols) => {
  const regex = /^[0-9]*$/;
  if (value.length <= numberOfSymbols && regex.test(value)) setProfile(setValue);
};
// (скрыт, т.к. для селекта гражданства)
// const passportCitizenships = [
//   {value: 'ABH', label: 'Абхазия'},
//   {value: 'AZE', label: 'Азербайджан'},
//   {value: 'ARM', label: 'Армения'},
//   {value: 'BLR', label: 'Белоруссия'},
//   {value: 'GEO', label: 'Грузия'},
//   {value: 'KAZ', label: 'Казахстан'},
//   {value: 'KGZ', label: 'Киргизия'},
//   {value: 'MDA', label: 'Молдавия'},
//   {value: 'RUS', label: 'Россия'},
//   {value: 'TJK', label: 'Таджикистан'},
//   {value: 'TKM', label: 'Туркменистан'},
//   {value: 'UZB', label: 'Узбекистан'},
//   {value: 'OST', label: 'Южная Осетия'},
// ]

export const DriversLicensePage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user, setUser } = useContext(Context);
  const [profile, setProfile] = useState(profileData);
  const [errorDatePassport, setErrorDatePassport] = useState(false);
  const [errorDateBirthday, setErrorDateBirthday] = useState(false);
  const [errorDateOfIssue, setErrorDateOfIssue] = useState(false);
  const [image, setImage] = useState('');
  const [imagesPassport, setImagesPassport] = useState<Image[]>([]);
  const [imagesDriversLicense, setImagesDriversLicense] = useState<Image[]>([]);
  const [driversList, setDriversList] = useState<[]>([]);
  const [isErrorPhone, setIsErrorPhone] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sending, setSending] = useState(false);
  const [updateListDrivers, setUpdateListDrivers] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  // (скрыт, т.к. для селекта гражданства)
  // const [passportCitizenship, setPassportCitizenship] = useState<{ value: string; label: string } | null>(null);
  // const [driverLicenseCitizenship, setDriverLicenseCitizenship] = useState<{ value: string; label: string } | null>(null);

  const [searchFilter, setSearchFilter] = useState('');

  const debouncedSearchTerm = useDebounce(searchFilter, 500);

  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  useEffect(() => {
    const rooDiv = document.getElementById('root');
    document.body.classList.add('off');
    if (popUpActive) {
      rooDiv.style.overflow = 'hidden';
      rooDiv.style.height = '100vh';
      rooDiv.style.position = 'relative';
    } else {
      document.body.classList.remove('off');
      rooDiv.style.overflow = 'unset';
    }
  }, [popUpActive]);
  useEffect(() => {
    setProfile(profileData);
  }, [popUpActive]);

  const emptyText = 'Поле обязательно к заполнению';

  const getDrivers = async (isPagination: boolean) => {
    !user.deleteDriver && toast.success('Водитель успешно удалён');

    try {
      const drivers = await fetchGet(`/drivers/filter`, {
        limit: 10,
        offset: isPagination ? offset : 0,
        searchString: searchFilter,
      });
      setTotalCount(drivers.response.count);
      if (isPagination && offset !== 0) {
        // @ts-ignore
        await setDriversList(prevState => [...prevState, ...drivers.response.rows]);
      } else {
        await setDriversList(drivers.response.rows);
      }
      if (!user.deleteDriver) {
        Object.assign(user, { deleteDriver: true });

        setUser({ ...user });

        localStorageWrite(user);
      }

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }
  };
  useEffect(() => {
    if (totalCount > offset) {
      getDrivers(true);
    }
  }, [offset]);
  useEffect(() => {
    setOffset(0);
    getDrivers(false);
  }, [updateListDrivers, debouncedSearchTerm]);

  useEffect(() => {
    setDisabled(
      !profile.name ||
        !profile.surname ||
        !profile.patronymic ||
        !profile.passportNumber ||
        !profile.driversLicenseNumber ||
        errorDatePassport ||
        errorDateBirthday ||
        errorDateOfIssue ||
        profile.phone?.length != 11 ||
        imagesPassport.length < 1 ||
        imagesDriversLicense.length < 1,
    );
  }, [
    profile.name,
    profile.surname,
    profile.patronymic,
    profile.phone,
    profile.passportNumber,
    profile.driversLicenseNumber,
    imagesDriversLicense,
    imagesPassport,
    errorDatePassport,
    errorDateBirthday,
    errorDateOfIssue,
  ]);

  function add1DayToDate(date: string) {
    let correctDate = new Date(date);
    return new Date(correctDate.setDate(correctDate.getDate() + 1));
  }
  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const sendDataDriver = async () => {
    if (!profile.surname || !profile.name || !profile.patronymic || !profile.phone) {
      toast.error('Заполните обязательные поля');
      setDisabled(false);
      setSending(false);
      return;
    }
    setSending(true);

    try {
      const addDriver = await fetchPost(`/drivers`, 'POST', clearObject({
        surname: profile.surname.trim() || null,
        name: profile.name.trim() || null,
        patronymic: profile.patronymic.trim() || null,
        profile: {
          birthDate: new Date(profile.birthDate),
          birthPlace: profile.birthPlace,
          email: profile.email || undefined,
        },
        passport: {
          passportSeries: profile.passportSeries || null,
          passportNumber: profile.passportNumber || null,
          passportDate: new Date(profile.passportDate),
          passportIssued: profile.passportIssued,
        },
        driverLicense: {
          driversLicenseSeries: profile.driversLicenseSeries || null,
          driversLicenseNumber: profile.driversLicenseNumber || null,
          driversLicenseDate: new Date(profile.driversLicenseDate),
        },
        phone: profile.phone || null,
      }));
      if (addDriver.error) {
        const errorMessages = addDriver.message.map(message => (
          <p>
            {message}
            <br />
            <br />
          </p>
        ));
        toast.error(<div>{errorMessages}</div>);
        setSending(false);
        return;
      }
      const correctionArrayFilesBody = [
        {
          communicationCategory: 'drivers',
          category: 'Паспорт',
          fileBelongsId: addDriver.response.id,
          files: imagesPassport?.map(file => file?.value),
          objectUuid: '',
        },
        {
          communicationCategory: 'drivers',
          category: 'Водительское удостоверение',
          fileBelongsId: addDriver.response.id,
          files: imagesDriversLicense?.map(file => file?.value),
          objectUuid: '',
        },
      ];
      const responseFile = await fetch(`${SUBSERVER}/api/files`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
        body: JSON.stringify(correctionArrayFilesBody),
      });
      setImagesDriversLicense([]);
      setImagesPassport([]);
      toast.success('Водитель успешно добавлен');
      closePopUp(setPopUpActive);
      setSending(false);
    } catch (e) {
      setSending(false);
      setDisabled(false);
      console.log(e);
    }
    setProfile({
      ...profile,
      surname: '',
      name: '',
      patronymic: '',
      passportSeries: '',
      passportNumber: '',
      passportDate: '',
      passportIssued: '',
      birthDate: '',
      birthPlace: '',
      driversLicenseSeries: '',
      driversLicenseNumber: '',
      driversLicenseDate: '',
      phone: '',
      email: '',
    });

    setDisabled(false);
    setSending(false);
    setPopUpActive(false);
    setUpdateListDrivers(!updateListDrivers);
    document.body.classList.remove('off');
  };
  const openPopupWithImg = src => {
    setImage(src);
    openPopUp(setPopUpActiveImg);
  };
  // (скрыт, т.к. для селекта гражданства)
  // let isPassportSeries = passportCitizenship && (passportCitizenship.value === 'ABH' || passportCitizenship.value === 'RUS' || passportCitizenship.value === 'OST');
  // let isDriverLicenseSeries = driverLicenseCitizenship && (driverLicenseCitizenship.value === 'ABH' || driverLicenseCitizenship.value === 'BLR' || driverLicenseCitizenship.value === 'GEO' || driverLicenseCitizenship.value === 'KAZ' || driverLicenseCitizenship.value === 'RUS' || driverLicenseCitizenship.value === 'OST');
  return (
    <>
      <PageWrapper style={{ zIndex: popUpActive ? '100000' : '100' }}>
        <ToastContainer />
        <div className={styles.titleAndSubTitleContainer}>
          <div className={styles.titleAndAddButton}>
            <TitleMain title={'Водители'} />
            <ButtonFilled
              sizeText={'small'}
              svg={<NewPlusSvg />}
              width={isMobile ? '32px' : '140px'}
              height={isMobile ? '32px' : '40px'}
              onClick={() => openPopUp(setPopUpActive)}
              text={isMobile ? '' : 'Добавить'}
            />
          </div>
          <SubTitleMain
            subTitle={`В настоящий момент у вас ${totalCount} ${declinationWord(totalCount, ['водитель', 'водителя', 'водителей'])}`}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <InputSearch
            placeholder="Поиск"
            onInput={({ currentTarget: { value: search } }) => setSearchFilter(search)}
            value={searchFilter}
          />
        </div>
        {driversList.length ? (
          <div className="DriversLicensePage__content">
            <div className="DriversLicensePage__titles">
              {['ФИО', 'Номер телефона'].map((item, index) => (
                <div className="DriversLicensePage__title" key={index}>
                  {item}
                </div>
              ))}
            </div>

            {skeleton ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '900px ',
                }}
              >
                <div>
                  <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                </div>
              </div>
            ) : (
              <DriverLicenseList list={driversList} />
            )}
          </div>
        ) : (
          <EmptyList title={'Водители отсутствуют'} subTitle={`В данный момент у вас нет водителей`} />
        )}
        <div className={popUpActive ? 'DriverPagePopup active' : 'DriverPagePopup'}>
          <div onClick={() => closePopUp(setPopUpActive)} className="DriverPagePopup__white"></div>
          {isMobile && <div className="DriverPagePopup__line"></div>}
          <div className="DriverPagePopup__wrapper">
            <div>
              <div className="DriverPagePopup__title">
                Добавление водителя
                <div onClick={() => closePopUp(setPopUpActive)} className="DriverPagePopup__close"></div>
              </div>
              <div className="DriverPagePopup__content">
                <div>
                  <div className="DriverPagePopup__box">
                    <div className="DriverPagePopup__subTitle">1. Личные данные водителя</div>
                    <div className="DriverPagePopup__inputBoxPersonal" style={{ margin: '24px 0 16px' }}>
                      <InputText
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Фамилия'}
                        textError={emptyText}
                        value={profile.surname}
                        setValue={value => setProfile({ ...profile, surname: upperCaseFirstLetter(value) })}
                        isLabel={true}
                        error={!profile.surname || profile.surname === ''}
                        fill={true}
                        margin={''}
                        disabled={false}
                        changeStyleLabel={true}
                      />
                      <InputText
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Имя'}
                        textError={emptyText}
                        value={profile.name}
                        setValue={value => setProfile({ ...profile, name: upperCaseFirstLetter(value) })}
                        isLabel={true}
                        error={!profile.name || profile.name === ''}
                        fill={true}
                        margin={''}
                        disabled={false}
                        changeStyleLabel={true}
                      />
                      <InputText
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Отчество'}
                        textError={emptyText}
                        value={profile.patronymic}
                        setValue={value => setProfile({ ...profile, patronymic: upperCaseFirstLetter(value) })}
                        isLabel={true}
                        error={!profile.patronymic || profile.patronymic === ''}
                        fill={true}
                        margin={''}
                        disabled={false}
                        changeStyleLabel={true}
                      />
                    </div>
                    <div className="DriverPagePopup__inputBoxPersonal">
                      <InputText
                        type={''}
                        secondError={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'E-mail'}
                        textError={'Введите корректный E-mail'}
                        value={profile.email}
                        setValue={value => setProfile({ ...profile, email: value })}
                        isLabel={true}
                        error={Boolean(profile.email) && !validateEmail(profile.email)}
                        fill={true}
                        margin={''}
                        showError={Boolean(profile.email) && !validateEmail(profile.email)}
                        disabled={false}
                      />
                      <div>
                        <MyPhoneInput
                          disabled={false}
                          setIsError={setIsErrorPhone}
                          isError={isErrorPhone}
                          isActive={isActive}
                          setIsActive={setIsActive}
                          value={profile.phone}
                          onChange={value => setProfile({ ...profile, phone: value })}
                          isFunction={null}
                          btnHelp={false}
                        />
                        <ErrorText
                          isError={isErrorPhone}
                          empty={isErrorPhone}
                          emptyText={emptyText}
                          correctText="Вы ввели некорректный номер телефона"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="DriverPagePopup__box">
                    <div className="DriverPagePopup__subTitle">2. Паспортные данные</div>
                    {/* Выбор страны гражданства (скрыт, т.к. отказались от валидации паспортов в зависимости от гражданства) */}
                    {/* <CustomSelect
                        error={false}
                        options={passportCitizenships}
                        placeholder={'Страна гражданства'}
                        noOptionsMessage={'Нет вариантов'}
                        value={passportCitizenship}
                        setValue={value => setPassportCitizenship(value)}
                        isSearchable={false}
                        isClearable={true}
                      /> */}
                    <div className="DriverPagePopup__inputBox DriverPagePopup__inputBox_sub">
                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Серия'}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.passportSeries}
                        setValue={value =>
                          validationPassport(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, passportSeries: value.toUpperCase() },
                            4,
                          )
                        }
                        isLabel={true}
                      />

                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Номер'}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.passportNumber}
                        setValue={value =>
                          validationPassport(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, passportNumber: value.toUpperCase() },
                            9,
                          )
                        }
                        isLabel={true}
                      />
                      <CustomDatePicker
                        width={'100%'}
                        placeholder={'Дата выдачи'}
                        value={profile.passportDate}
                        setValue={value => setProfile({ ...profile, passportDate: value })}
                        height="48px"
                      />

                      <CustomDatePicker
                        width={'100%'}
                        placeholder={'Дата рождения'}
                        value={profile.birthDate}
                        setValue={birthDate => setProfile({ ...profile, birthDate })}
                        height="48px"
                      />
                    </div>
                    <InputText
                      type={''}
                      secondError={false}
                      helpText={''}
                      widthHelp={0}
                      placeholder={'Кем выдан'}
                      textError={''}
                      value={profile.passportIssued}
                      setValue={value => setProfile({ ...profile, passportIssued: value })}
                      isLabel={true}
                      error={false}
                      fill={false}
                      margin={'16px'}
                      disabled={false}
                    />
                    <InputText
                      type={''}
                      secondError={false}
                      helpText={''}
                      widthHelp={0}
                      placeholder={'Место рождения'}
                      textError={''}
                      value={profile.birthPlace}
                      setValue={value => setProfile({ ...profile, birthPlace: value })}
                      isLabel={true}
                      error={false}
                      fill={false}
                      margin={'16px'}
                      disabled={false}
                    />
                    <div style={{ marginTop: '24px' }}>
                      <ProfilePageUploading
                        name={'Паспорт'}
                        desc={'Разворот с фото, разворот с пропиской'}
                        setImages={setImagesPassport}
                        images={imagesPassport}
                        count={2}
                      />
                      {imagesPassport.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                        <div className="image-container" key={id}>
                          <ImageContainer
                            id={id}
                            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={type}
                            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                          >
                            <ButtonClose
                              id={id}
                              onClick={({ target }) => removeImage(target, imagesPassport, setImagesPassport)}
                              hidden=""
                            />
                          </ImageContainer>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="DriverPagePopup__box">
                    <div className="DriverPagePopup__subTitle">3. Водительское удостоверение</div>
                    {/* Выбор страны выдачи (скрыт, т.к. отказались от валидации паспортов в зависимости от гражданства) */}
                    {/* <CustomSelect
                        error={false}
                        options={passportCitizenships}
                        placeholder={'Страна выдачи'}
                        noOptionsMessage={'Нет вариантов'}
                        value={driverLicenseCitizenship}
                        setValue={value => setDriverLicenseCitizenship(value)}
                        isSearchable={false}
                        isClearable={true}
                      /> */}
                    <div className="DriverPagePopup__inputBox DriverPagePopup__inputBox_sub">
                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        widthHelp={0}
                        placeholder={'Серия'}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.driversLicenseSeries}
                        setValue={value =>
                          validationPassport(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, driversLicenseSeries: value.toUpperCase() },
                            4,
                          )
                        }
                        isLabel={true}
                      />
                      <InputText
                        type={''}
                        disabled={false}
                        helpText={''}
                        error={false}
                        secondError={false}
                        textError={''}
                        fill={false}
                        placeholder={'Номер'}
                        margin={isMobile ? '12px' : '0'}
                        value={profile.driversLicenseNumber}
                        setValue={value =>
                          validationPassport(
                            value.toUpperCase(),
                            setProfile,
                            { ...profile, driversLicenseNumber: value.toUpperCase() },
                            9,
                          )
                        }
                        isLabel={true}
                      />

                      {/*
                        <InputNumber
                          bgError={true}
                          fill={false}
                          placeholder={'Номер'}
                          margin={isMobile ? '12px' : '0'}
                          isValue={profile.driversLicenseNumber}
                          setIsValue={value =>
                            validationDriversLicenseNumber(
                              value,
                              setProfile,
                              { ...profile, driversLicenseNumber: value },
                              4,
                            )
                          }
                        />*/}
                    </div>
                    <div style={{ margin: '16px 0 0' }}>
                      <CustomDatePicker
                        width={'100%'}
                        placeholder={'Дата выдачи'}
                        value={profile.driversLicenseDate}
                        setValue={value => setProfile({ ...profile, driversLicenseDate: value })}
                        height="48px"
                      />
                    </div>
                    <div style={{ marginTop: '24px', marginBottom: '48px' }}>
                      <ProfilePageUploading
                        name={'Водительское удостоверение'}
                        desc={'Лицевая и оборотная сторона'}
                        setImages={setImagesDriversLicense}
                        images={imagesDriversLicense}
                        count={2}
                      />
                      {imagesDriversLicense.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                        <div className="image-container" key={id}>
                          <ImageContainer
                            id={id}
                            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                            name={name}
                            size={size}
                            isSize={isSize}
                            date={date}
                            isDate={isDate}
                            type={type}
                            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
                          >
                            <ButtonClose
                              id={id}
                              onClick={({ target }) =>
                                removeImage(target, imagesDriversLicense, setImagesDriversLicense)
                              }
                              hidden=""
                            />
                          </ImageContainer>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <ButtonFilled width={'100%'} onClick={sendDataDriver} text={'Сохранить'} disabled={disabled} />
              </div>
            </div>
            <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
              <div onClick={() => closePopUp(setPopUpActiveImg)} className="Popup__white"></div>
              <div className="Popup__wrapper">
                <div>
                  <div>
                    <div onClick={() => closePopUp(setPopUpActiveImg)} className="Popup__close"></div>
                    <div className="Popup__content">
                      <div className="Popup__box">
                        <img src={image} alt={'Транспортная накладная'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
