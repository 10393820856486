import React from 'react';
import RubbleSvg from 'src/components/UI/svg-icon/RubbleSvg';
import CrushingScreeningSvg from '../../components/UI/svg-icon/CrushingScreeningSvg';
import SandSvg from '../../components/UI/svg-icon/SandSvg';
import PscSvg from '../../components/UI/svg-icon/PscSvg';
import AsgSvg from '../../components/UI/svg-icon/AsgSvg';
import {
    dataCardResponseType,
    ICheckBox, IMaterialButtons,
    IOptions, ITypeConvertNameMaterialToTrueValue,
    ITypeInputsOfMaterial, ITypeKindMaterialOptions,
    ITypeMaterialsOptionsInputs,
    ITypeOfArrayInputsOfMaterial
} from './type';
import uuid from 'react-uuid';
import RubbleFullSvg from '../../components/UI/svg-icon/RubbleFullSvg';
import CrushingScreeningFullSvg from '../../components/UI/svg-icon/CrushingScreeningFullSvg';
import SandFullSvg from '../../components/UI/svg-icon/SandFullSvg';
import PscFullSvg from '../../components/UI/svg-icon/PscFullSvg';
import AsgFullSvg from '../../components/UI/svg-icon/AsgFullSvg';

export const arrayMaterialButtons:IMaterialButtons[] = [
    { title:'Щебень', svg:<RubbleSvg/>, active:false, value: 'rubble', disabled:false, },
    { title:'Вторичный щебень', svg:<CrushingScreeningSvg/>, active:false, value: 'secondary-crushed-stone', disabled:false, },
    { title:'Песок', svg:<SandSvg/>, active:false, value: 'sand', disabled:false, },
    { title:'ЩПС', svg:<PscSvg/>, active:false, value: 'psc', disabled:false, },
    { title:'ПГС', svg:<AsgSvg/>, active:false, value: 'asg', disabled:false, },
];
export const convertNameFromValueMaterial = {
    rubble:'Щебень',
    'secondary-crushed-stone':'Вторичный щебень',
    sand:'Песок',
    psc:'ЩПС',
    asg:'ПГС',
};
export const optionDefaultForTypeMaterialSelect = {
    rubble: { value: 'rubble', label: 'Щебень' },
    'secondary-crushed-stone': { value: 'secondary-crushed-stone', label: 'Вторичный щебень' },
    sand: { value: 'sand', label: 'Песок' },
    psc: { value: 'psc', label: 'ЩПС' },
    asg: { value: 'asg', label: 'ПГС' },
};
export const optionsSelectMaterial:IOptions[] = [
    { value: 'rubble', label: 'Щебень' },
    { value: 'secondary-crushed-stone', label: 'Вторичный щебень' },
    { value: 'sand', label: 'Песок' },
    { value: 'psc', label: 'ЩПС' },
    { value: 'asg', label: 'ПГС' },
];
export const svgByMaterial = {
    rubble:<RubbleFullSvg/>,
    'secondary-crushed-stone':<CrushingScreeningFullSvg/>,
    sand:<SandFullSvg/>,
    psc:<PscFullSvg/>,
    asg:<AsgFullSvg/>,
};
export const initStateCheckBox:ICheckBox = {
    id: '',
    active: false,
};
export const initStateInputsOfMaterial:ITypeInputsOfMaterial= {
    rubble: {
        prices: [{ region: 'Все', value: 0, id: uuid(),error:false }],
        volume: 0,
        description: '',
        name: '',
        sparseness: '',
        kind: '',
        frostResistance: '',
        fraction: '',
        mark: '',
        bulkDensity: '',
        photo: [],
        passport: [],
        filtration: '',
    },
    'secondary-crushed-stone': {
        volume: 0,
        description: '',
        name: '',
        passport: [],
        photo: [],
        prices: [{ region: 'Все', value: 0, id: uuid(),error:false }],
        mark: '',
        kind: '',
        sparseness: '',
        bulkDensity: '',
        filtration: '',
        frostResistance: '',
        substance: '',
        fraction: '',
    },
    sand: {
        prices: [{ region: 'Все', value: 0, id: uuid(),error:false }],
        volume: 0,
        description: '',
        name: '',
        filtrationCoefficient: '',
        coarseness: '',
        bulkDensity: '',
        kind: '',
        photo: [],
        passport: [],
        classification: '',
    },
    psc: {
        prices: [{ region: 'Все', value: 0, id: uuid(),error:false }],
        volume: 0,
        description: '',
        name: '',
        type: '',
        kind: '',
        bulkDensity: '',
        photo: [],
        passport: [],
        mark: '',
        sparseness: '',
        filtration: '',
        frostResistance: '',
    },
    asg: {
        prices: [{ region: 'Все', value: 0, id: uuid(),error:false }],
        volume: 0,
        description: '',
        name: '',
        kind: '',
        bulkDensity: '',
        photo: [],
        passport: [],
        mark: '',
        sparseness: '',
        filtration: '',
        frostResistance: '',
        category: '',
        fraction: ''
    },
};
export const convertNameMaterialToTrueValue:ITypeConvertNameMaterialToTrueValue = {
    rubble:'rubble',
    secondaryCrushedStone:'secondary-crushed-stone',
    sand:'sand',
    psc:'psc',
    asg:'asg'
};
export const optionsKindOfMaterial:ITypeKindMaterialOptions = {
    rubble: [
        { value: undefined, label: 'Все' },
        { value: 'Гранитный', label: 'Гранитный' },
        { value: 'Гравийный', label: 'Гравийный' },
        { value: 'Известняковый', label: 'Известняковый' },
    ],
    'secondary-crushed-stone': [
        { value: undefined, label: 'Все' },
        { value: 'Отсев дробления', label: 'Отсев дробления' },
        { value: 'Бетонный', label: 'Бетонный' },
        { value: 'Кирпичный бой', label: 'Кирпичный бой' },
    ],
    sand: [
        { value: undefined, label: 'Все' },
        { value: 'Пескогрунт', label: 'Пескогрунт' },
        { value: 'Карьерный', label: 'Карьерный' },
        { value: 'Сеяный', label: 'Сеяный' },
        { value: 'Мытый', label: 'Мытый' },
        { value: 'Намывной', label: 'Намывной' },
        { value: 'Речной', label: 'Речной' },
        { value: 'Кварцевый', label: 'Кварцевый' },
    ],
    psc: [
        { value: undefined, label: 'Все' },
        { value: 'с1 (самая крупная фракция 40 мм)', label: 'с1 (самая крупная фракция 40 мм)' },
        { value: 'с2 (самая крупная фракция 20 мм)', label: 'с2 (самая крупная фракция 20 мм)' },
        { value: 'с3 (размер зерен 120 мм и меньше)', label: 'с3 (размер зерен 120 мм и меньше)' },
        { value: 'с4 (самая крупная фракция 80мм)', label: 'с4 (самая крупная фракция 80мм)' },
        { value: 'с5 (самая крупная фракция 40мм)', label: 'с5 (самая крупная фракция 40мм)' },
        { value: 'с6 (самая крупная фракция 20 мм)', label: 'с6 (самая крупная фракция 20 мм)' },
        { value: 'с7 (самая крупная фракция 10 мм)', label: 'с7 (самая крупная фракция 10 мм)' },
        { value: 'с8 (самая крупная фракция 5 мм)', label: 'с8 (самая крупная фракция 5 мм)' },
        { value: 'с9 (самая крупная фракция 80 мм)', label: 'с9 (самая крупная фракция 80 мм)' },
        { value: 'с10 (самая крупная фракция 40 мм)', label: 'с10 (самая крупная фракция 40 мм)' },
        { value: 'с11 (самая крупная фракция 20 мм)', label: 'с11 (самая крупная фракция 20 мм)' },
    ],
    asg: [
        { value: undefined, label: 'Все' },
        { value: 'Натуральная ПГС', label: 'Натуральная ПГС' },
        { value: 'Обогащенная ПГС', label: 'Обогащенная ПГС' },
    ],
};
export const optionsInputsOfMaterial:ITypeMaterialsOptionsInputs = {
    rubble:
        {
            kind: [
                { value: 'Гранитный', label: 'Гранитный' },
                { value: 'Гравийный', label: 'Гравийный' },
                { value: 'Известняковый', label: 'Известняковый' },
            ],
            fraction: [
                { value: '0-4', label: '0-4' },
                { value: '4-8', label: '4-8' },
                { value: '4-16', label: '4-16' },
                { value: '8-11,2', label: '8-11,2' },
                { value: '8-16', label: '8-16' },
                { value: '11,2-16', label: '11,2-16' },
                { value: '16-22,4', label: '16-22,4' },
                { value: '16-31,5', label: '16-31,5' },
                { value: '22,4-31,5', label: '22,4-31,5' },
                { value: '31,5-45', label: '31,5-45' },
                { value: '25-60', label: '25-60' },
                { value: '31,5-63', label: '31,5-63' },
                { value: '5-10', label: '5-10' },
                { value: '5-20', label: '5-20' },
                { value: '20-40', label: '20-40' },
                { value: '40-70', label: '40-70' },
                { value: '70-100', label: '70-100' },
            ],
            mark: [
                { value: 'М100', label: 'М100' },
                { value: 'М200', label: 'М200' },
                { value: 'М300', label: 'М300' },
                { value: 'М400', label: 'М400' },
                { value: 'М500', label: 'М500' },
                { value: 'М600', label: 'М600' },
                { value: 'М700', label: 'М700' },
                { value: 'М800', label: 'М800' },
                { value: 'М900', label: 'М900' },
                { value: 'М1000', label: 'М1000' },
                { value: 'М1100', label: 'М1100' },
                { value: 'М1200', label: 'М1200' },
                { value: 'М1300', label: 'М1300' },
                { value: 'М1400', label: 'М1400' },
            ],
            frostResistance: [
                { value: 'F-15', label: 'F-15' },
                { value: 'F-25', label: 'F-25' },
                { value: 'F-50', label: 'F-50' },
                { value: 'F-100', label: 'F-100' },
                { value: 'F-150', label: 'F-150' },
                { value: 'F-200', label: 'F-200' },
                { value: 'F-300', label: 'F-300' },
                { value: 'F-400', label: 'F-400' },
            ],
        },
    'secondary-crushed-stone':
        {
            kind: [
                { value: 'Отсев дробления', label: 'Отсев дробления' },
                { value: 'Бетонный', label: 'Бетонный' },
                { value: 'Кирпичный бой', label: 'Кирпичный бой' },
            ],
            fractionScreening: [
                { value: '0-10', label: '0-10' },
                { value: '0-20', label: '0-20' },
                { value: '0-40', label: '0-40' },
                { value: '0-70', label: '0-70' },
                { value: '0-100', label: '0-100' },
            ],
            fractionConcrete: [
                { value: '5-20', label: '5-20' },
                { value: '20-40', label: '20-40' },
                { value: '40-70', label: '40-70' },
            ],
            fractionBrick: [
                { value: '0-100', label: '0-100' },
            ],
            substance: [
                { value: '1 класс (менее 3%)', label: '1 класс (менее 3%)' },
                { value: '2 класс (до 30 %)', label: '2 класс (до 30 %)' },
                { value: '3 класс (выше 30%)', label: '3 класс (выше 30%)' },
            ],
            frostResistance: [
                { value: 'F-15', label: 'F-15' },
                { value: 'F-25', label: 'F-25' },
                { value: 'F-50', label: 'F-50' },
                { value: 'F-100', label: 'F-100' },
                { value: 'F-150', label: 'F-150' },
                { value: 'F-200', label: 'F-200' },
                { value: 'F-300', label: 'F-300' },
                { value: 'F-400', label: 'F-400' },
            ],
            mark: [
                { value: 'М100', label: 'М100' },
                { value: 'М200', label: 'М200' },
                { value: 'М300', label: 'М300' },
                { value: 'М400', label: 'М400' },
                { value: 'М500', label: 'М500' },
                { value: 'М600', label: 'М600' },
                { value: 'М700', label: 'М700' },
                { value: 'М800', label: 'М800' },
                { value: 'М900', label: 'М900' },
                { value: 'М1000', label: 'М1000' },
                { value: 'М1100', label: 'М1100' },
                { value: 'М1200', label: 'М1200' },
                { value: 'М1300', label: 'М1300' },
                { value: 'М1400', label: 'М1400' },
            ],
        },
    sand:
        {
            kind: [
                { value: 'Пескогрунт', label: 'Пескогрунт' },
                { value: 'Карьерный', label: 'Карьерный' },
                { value: 'Сеяный', label: 'Сеяный' },
                { value: 'Мытый', label: 'Мытый' },
                { value: 'Намывной', label: 'Намывной' },
                { value: 'Речной', label: 'Речной' },
                { value: 'Кварцевый', label: 'Кварцевый' },
            ],
            coarseness: [
                { value: 'До 0,7 (очень тонкий)', label: 'До 0,7 (очень тонкий)' },
                { value: '0,7-1,0 (тонкий)', label: '0,7-1,0 (тонкий)' },
                { value: '1,0-1,5 (очень мелкий)', label: '1,0-1,5 (очень мелкий)' },
                { value: '1,5-2,0 (мелкий)', label: '1,5-2,0 (мелкий)' },
                { value: '2,0-2,5 (средний)', label: '2,0-2,5 (средний)' },
                { value: '2,5-3,0 (крупный)', label: '2,5-3,0 (крупный)' },
                { value: '3,0-3,5 (повышенной крупности)', label: '3,0-3,5 (повышенной крупности)' },
                { value: 'Свыше 3,5 (очень крупный)', label: 'Свыше 3,5 (очень крупный)' },
            ],
            classification: [
                { value: '1 класс (содержание пылевидных частиц и глины не более 2-3 %)',
                    label: '1 класс (содержание пылевидных частиц и глины не более 2-3 %)' },
                { value: '2 класс (содержание пылевидных частиц и глины не более от 3 до 10 %)',
                    label: '2 класс (содержание пылевидных частиц и глины не более от 3 до 10 %)' },
            ],
        },
    psc:
        {
            kind: [
                { value: 'с1 (самая крупная фракция 40 мм)', label: 'с1 (самая крупная фракция 40 мм)' },
                { value: 'с2 (самая крупная фракция 20 мм)', label: 'с2 (самая крупная фракция 20 мм)' },
                { value: 'с3 (размер зерен 120 мм и меньше)', label: 'с3 (размер зерен 120 мм и меньше)' },
                { value: 'с4 (самая крупная фракция 80мм)', label: 'с4 (самая крупная фракция 80мм)' },
                { value: 'с5 (самая крупная фракция 40мм)', label: 'с5 (самая крупная фракция 40мм)' },
                { value: 'с6 (самая крупная фракция 20 мм)', label: 'с6 (самая крупная фракция 20 мм)' },
                { value: 'с7 (самая крупная фракция 10 мм)', label: 'с7 (самая крупная фракция 10 мм)' },
                { value: 'с8 (самая крупная фракция 5 мм)', label: 'с8 (самая крупная фракция 5 мм)' },
                { value: 'с9 (самая крупная фракция 80 мм)', label: 'с9 (самая крупная фракция 80 мм)' },
                { value: 'с10 (самая крупная фракция 40 мм)', label: 'с10 (самая крупная фракция 40 мм)' },
                { value: 'с11 (самая крупная фракция 20 мм)', label: 'с11 (самая крупная фракция 20 мм)' },
            ],
            type: [
                { value: 'Известняковый', label: 'Известняковый' },
                { value: 'Гранитный', label: 'Гранитный' },
            ],
            frostResistance: [
                { value: 'F-15', label: 'F-15' },
                { value: 'F-25', label: 'F-25' },
                { value: 'F-50', label: 'F-50' },
                { value: 'F-100', label: 'F-100' },
                { value: 'F-150', label: 'F-150' },
                { value: 'F-200', label: 'F-200' },
                { value: 'F-300', label: 'F-300' },
                { value: 'F-400', label: 'F-400' },
            ],
            mark: [
                { value: 'М100', label: 'М100' },
                { value: 'М200', label: 'М200' },
                { value: 'М300', label: 'М300' },
                { value: 'М400', label: 'М400' },
                { value: 'М500', label: 'М500' },
                { value: 'М600', label: 'М600' },
                { value: 'М700', label: 'М700' },
                { value: 'М800', label: 'М800' },
                { value: 'М900', label: 'М900' },
                { value: 'М1000', label: 'М1000' },
                { value: 'М1100', label: 'М1100' },
                { value: 'М1200', label: 'М1200' },
                { value: 'М1300', label: 'М1300' },
                { value: 'М1400', label: 'М1400' },
            ],
        },
    asg:
        {
            kind: [
                { value: 'Натуральная ПГС', label: 'Натуральная ПГС' },
                { value: 'Обогащенная ПГС', label: 'Обогащенная ПГС' },
            ],
            fraction: [
                { value: '0-10 мм', label: '0-10 мм' },
                { value: '5-20 мм', label: '5-20 мм' },
                { value: '10-20 мм', label: '10-20 мм' },
                { value: '20-40 мм', label: '20-40 мм' },
                { value: '40-70 мм', label: '40-70 мм' },
            ],
            mark: [
                { value: 'М800', label: 'М800' },
                { value: 'М900', label: 'М900' },
                { value: 'М1000', label: 'М1000' },
                { value: 'М1100', label: 'М1100' },
                { value: 'М1200', label: 'М1200' },
                { value: 'М1300', label: 'М1300' },
                { value: 'М1400', label: 'М1400' },
            ],
            frostResistance: [
                { value: 'F-15', label: 'F-15' },
                { value: 'F-25', label: 'F-25' },
                { value: 'F-50', label: 'F-50' },
                { value: 'F-100', label: 'F-100' },
                { value: 'F-150', label: 'F-150' },
                { value: 'F-200', label: 'F-200' },
                { value: 'F-300', label: 'F-300' },
                { value: 'F-400', label: 'F-400' },
            ],
            category: [
                { value: 'I (процент гравия от 15 до 25%)', label: 'I (процент гравия от 15 до 25%)' },
                { value: 'II (процент гравия от 25 до 35%)', label: 'II (процент гравия от 25 до 35%)' },
                { value: 'III (процент гравия от 35 до 50%)', label: 'III (процент гравия от 35 до 50%)' },
                { value: 'IV (процент гравия от 50 до 65%)', label: 'IV (процент гравия от 50 до 65%)' },
                { value: 'V (процент гравия от 65 до 75%)', label: 'V (процент гравия от 65 до 75%)' },
            ],
        },
};
export const optionsAreaOfPrice:IOptions[] = [
    { value: 'Москва', label: 'Москва' },
    { value: 'Московская область', label: 'Московская область' },
];
const chooseOptionFractionSecondaryCrushedStone = {
    'Отсев дробления':optionsInputsOfMaterial['secondary-crushed-stone'].fractionScreening,
    'Кирпичный бой':optionsInputsOfMaterial['secondary-crushed-stone'].fractionBrick,
    'Бетонный':optionsInputsOfMaterial['secondary-crushed-stone'].fractionConcrete,
};
export const arrayInputsOfMaterial = (
    valueOfInputsOfMaterial:ITypeInputsOfMaterial,
    setValueOfInputsOfMaterial:React.Dispatch<React.SetStateAction<ITypeInputsOfMaterial>>,
):ITypeOfArrayInputsOfMaterial => {
    return {
        rubble:[
            { title:'1. Вид материала' , input:'select', options:optionsInputsOfMaterial.rubble.kind,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,kind:e } })),
                value:valueOfInputsOfMaterial.rubble.kind },
            { title:'2. Фракция материала' , input:'select', options:optionsInputsOfMaterial.rubble.fraction,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,fraction:e } })),
                value:valueOfInputsOfMaterial.rubble.fraction },
            { title:'3. Прочность материала' , input:'select', options:optionsInputsOfMaterial.rubble.mark,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,mark:e } })),
                value:valueOfInputsOfMaterial.rubble.mark },
            { title:'4. Морозостойкость материала' , input:'select', options:optionsInputsOfMaterial.rubble.frostResistance,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,frostResistance:e } })),
                value:valueOfInputsOfMaterial.rubble.frostResistance },
            { title:'5. Коэффициент фильтрации' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,filtration:e } })),
                value:valueOfInputsOfMaterial.rubble.filtration },
            { title:'6. Насыпная плотность' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,bulkDensity:e } })),
                value:valueOfInputsOfMaterial.rubble.bulkDensity },
            { title:'7. Лещадность, %' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,sparseness:e } })),
                value:valueOfInputsOfMaterial.rubble.sparseness },
            { title:'8. Остаток материала на карьере, т' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,rubble:{ ...prevState.rubble,volume:Number(e) } })),
                value:valueOfInputsOfMaterial.rubble.volume },
        ],
        'secondary-crushed-stone':[
            { title:'1. Вид материала' , input:'select', options:optionsInputsOfMaterial['secondary-crushed-stone'].kind,
                disabled:false, error:false,
                onChange:(e:string)=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],kind:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].kind },
            { title:'2. Фракция материала' , input:'select',
                options:chooseOptionFractionSecondaryCrushedStone[valueOfInputsOfMaterial['secondary-crushed-stone'].kind.value],
                disabled:!valueOfInputsOfMaterial['secondary-crushed-stone'].kind.value, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],fraction:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].fraction },
            { title:'3. Содержания кирпича' , input:'select', options:optionsInputsOfMaterial['secondary-crushed-stone'].substance,
                disabled:valueOfInputsOfMaterial['secondary-crushed-stone'].kind.value !=='Бетонный', error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],substance:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].substance },
            { title:'4. Морозостойкость материала' , input:'select', options:optionsInputsOfMaterial['secondary-crushed-stone'].frostResistance,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],frostResistance:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].frostResistance },
            { title:'5. Прочность материала' , input:'select', options:optionsInputsOfMaterial['secondary-crushed-stone'].mark,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],mark:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].mark },
            { title:'6. Коэффициент фильтрации' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],filtration:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].filtration },
            { title:'7. Насыпная плотность' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],bulkDensity:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].bulkDensity },
            { title:'8. Лещадность, %' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],sparseness:e } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].sparseness },
            { title:'9. Остаток материала на карьере, т' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,'secondary-crushed-stone':
                        { ...prevState['secondary-crushed-stone'],volume:Number(e) } })),
                value:valueOfInputsOfMaterial['secondary-crushed-stone'].volume },
        ],
        sand:[
            { title:'1. Вид материала' , input:'select', options:optionsInputsOfMaterial.sand.kind,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,sand:{ ...prevState.sand,kind:e } })),
                value:valueOfInputsOfMaterial.sand.kind },
            { title:'2. Модуль крупности' , input:'select', options:optionsInputsOfMaterial.sand.coarseness,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,sand:{ ...prevState.sand,coarseness:e } })),
                value:valueOfInputsOfMaterial.sand.coarseness },
            { title:'3. Классификация материала' , input:'select', options:optionsInputsOfMaterial.sand.classification,
                disabled:valueOfInputsOfMaterial.sand.kind.value === 'Пескогрунт', error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,sand:{ ...prevState.sand,classification:e } })),
                value:valueOfInputsOfMaterial.sand.classification },
            { title:'4. Коэффициент фильтрации' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,sand:{ ...prevState.sand,filtrationCoefficient:e } })),
                value:valueOfInputsOfMaterial.sand.filtrationCoefficient },
            { title:'5. Насыпная плотность' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,sand:{ ...prevState.sand,bulkDensity:e } })),
                value:valueOfInputsOfMaterial.sand.bulkDensity },
            { title:'6. Остаток материала на карьере, т' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,sand:{ ...prevState.sand,volume:Number(e) } })),
                value:valueOfInputsOfMaterial.sand.volume },
        ],
        psc:[
            { title:'1. Вид материала' , input:'select', options:optionsInputsOfMaterial.psc.kind,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState, psc:{ ...prevState.psc,kind:e } })),
                value:valueOfInputsOfMaterial.psc.kind },
            { title:'2. Типы щебня' , input:'select', options:optionsInputsOfMaterial.psc.type,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,psc:{ ...prevState.psc,type:e } })),
                value:valueOfInputsOfMaterial.psc.type },
            { title:'3. Морозостойкость материала' , input:'select', options:optionsInputsOfMaterial.psc.frostResistance,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,psc:{ ...prevState.psc,frostResistance:e } })),
                value:valueOfInputsOfMaterial.psc.frostResistance },
            { title:'4. Прочность материала' , input:'select', options:optionsInputsOfMaterial.psc.mark,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,psc:{ ...prevState.psc,mark:e } })),
                value:valueOfInputsOfMaterial.psc.mark },
            { title:'5. Коэффициент фильтрации' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,psc:{ ...prevState.psc,filtration:e } })),
                value:valueOfInputsOfMaterial.psc.filtration },
            { title:'6. Насыпная плотность' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,psc:{ ...prevState.psc,bulkDensity:e } })),
                value:valueOfInputsOfMaterial.psc.bulkDensity },
            { title:'7. Лещадность, %' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,psc:{ ...prevState.psc,sparseness:e } })),
                value:valueOfInputsOfMaterial.psc.sparseness },
            { title:'8. Остаток материала на карьере, т' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,psc:{ ...prevState.psc,volume:Number(e) } })),
                value:valueOfInputsOfMaterial.psc.volume },
        ],
        asg:[
            { title:'1. Вид материала' , input:'select', options:optionsInputsOfMaterial.asg.kind,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg:{ ...prevState.asg,kind:e } })),
                value:valueOfInputsOfMaterial.asg.kind },
            { title:'2. Фракция материала' , input:'select', options:optionsInputsOfMaterial.asg.fraction,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,fraction:e } })),
                value:valueOfInputsOfMaterial.asg.fraction },
            { title:'3. Прочность материала' , input:'select', options:optionsInputsOfMaterial.asg.mark,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,mark:e } })),
                value:valueOfInputsOfMaterial.asg.mark },
            { title:'4. Морозостойкость материала' , input:'select', options:optionsInputsOfMaterial.asg.frostResistance,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,frostResistance:e } })),
                value:valueOfInputsOfMaterial.asg.frostResistance },
            { title:'5. Категория материала' , input:'select', options:optionsInputsOfMaterial.asg.category,
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,category:e } })),
                value:valueOfInputsOfMaterial.asg.category },
            { title:'6. Коэффициент фильтрации' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,filtration:e } })),
                value:valueOfInputsOfMaterial.asg.filtration },
            { title:'7. Насыпная плотность' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,bulkDensity:e } })),
                value:valueOfInputsOfMaterial.asg.bulkDensity },
            { title:'8. Лещадность, %' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,sparseness:e } })),
                value:valueOfInputsOfMaterial.asg.sparseness },
            { title:'9. Остаток материала на карьере, т' , input:'', options:'',
                disabled:false, error:false,
                onChange:e=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,asg:{ ...prevState.asg,volume:Number(e) } })),
                value:valueOfInputsOfMaterial.asg.volume },
        ],
    };
};
export const initStateDataCardResponse:dataCardResponseType[] = [{
    id: 0,
    title: '',
    link: '',
    description: '',
    isEnabledLink: false,
    files: [
        {
            id: 0,
            communicationCategory: '',
            fileBelongsId: 0,
            name: '',
            category: '',
            mimeType: '',
            location: '',
        },
        {
            id: 0,
            communicationCategory: '',
            fileBelongsId: 0,
            name: '',
            category: '',
            mimeType: '',
            location: '',
        }
    ],
    quarry:{
        address: {
            address: '',
            latitude: 0,
            longitude: 0,
        },
        id:0,
        name:'',
        quarryType:'',
    },
}];
